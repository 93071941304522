import { memo, useCallback } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image as ChakraImage,
  Spinner,
  Text,
} from '@chakra-ui/react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import AcademicCap from '../assets/icon/AcademicCap.svg'
import ChatAlt from '../assets/icon/ChatAlt.svg'
import DocumentSearch from '../assets/icon/DocumentSearch.svg'
import Heart from '../assets/icon/Heart.svg'
import { HomeIcon } from '../assets/icon/HomeIcon'
import ThumbUp from '../assets/icon/ThumbUp.svg'
import UserIcon from '../assets/icon/UserIcon.svg'
import Banner from '../assets/images/menu_scout_bn.jpg'
import { useWindowSize } from '../hooks/useWindowSize'
import { GTMClickItemName } from '../utils/gtm/types/common'

import { ChakraLink } from './ChakraLink'

interface Props {
  mode: 'talent' | 'guide'
  isLogin: boolean | undefined
  isOpen: boolean
  onClose: () => void
  isLocal: boolean
  talentAppUrl?: string
  guideAppUrl?: string
  unreadScoutCount: number
  keptJobsCount: number
}

const Menu = memo((props: Props) => {
  const {
    mode: appMode,
    isLogin,
    isOpen,
    onClose,
    isLocal,
    talentAppUrl,
    guideAppUrl,
    unreadScoutCount,
    keptJobsCount,
  } = props
  const { isPcView } = useWindowSize()
  const router = useRouter()

  // local開発環境でポートが変わる都合、リンクをpathではなくurlにしたい
  const constructHref = (href: string, linkTo: 'talent' | 'guide') => {
    const hostName = linkTo === 'talent' ? talentAppUrl : guideAppUrl
    return isLocal ? `${hostName}${href}` : href
  }

  // props: href, onClickの2つに同値を渡す都合、変数化している（並びは登場順）
  const hrefTalentLogin = constructHref('/login', 'talent')
  const hrefTalentRegistration = constructHref('/registration/agent', 'talent')
  const hrefTalentScout = constructHref('/scout', 'talent')
  const hrefTalentJobKeepList = constructHref('/job/keep-list', 'talent')
  const hrefTalentHome = constructHref('/', 'talent')
  const hrefTalentInquiryList = constructHref('/inquiry/list', 'talent')
  const hrefTalentJob = constructHref('/job', 'talent')
  const hrefGuideHome = constructHref('/guide', 'guide')
  const hrefTalentAccount = constructHref('/account', 'talent')

  const onClickLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
      if (
        href === router.pathname ||
        (href === talentAppUrl + router.pathname && router.basePath !== '/guide') ||
        // router.pathnameが'/'の時はguideのTOPの為、判定でrouter.pathnameを省く
        (href
          === guideAppUrl + router.basePath + (router.pathname === '/' ? '' : router.pathname) &&
          router.basePath === '/guide')
      ) {
        // SPEC: 同一ページ遷移企図に対しては、ドロワーメニュークローズのみ行い、画面遷移はしない
        e.preventDefault()
        onClose()
      }
    },
    [router.pathname],
  ) // router.asPathだと、クエリ変化に巻き込まれるのでrouter.pathnameを依存値としてwatch

  return (
    <Drawer size={isPcView ? 'sm' : 'full'} isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        bg="white"
        height="100svh"
        maxH="100svh"
        sx={{
          overflowY: 'scroll',
          '-webkit-overflow-scrolling': 'touch',
        }}
      >
        <DrawerHeader borderBottom="1px solid" borderBottomColor="border.primary">
          <Flex alignItems="center" justifyContent="center">
            <Box minW="24px" />
            <Flex width="100%" fontSize="16px" justifyContent="center">
              メニュー
            </Flex>
            <DrawerCloseButton
              _focusVisible={{ boxShadow: 'none' }}
              position="inherit"
              top={0}
              right={0}
              w="24px"
              h="24px"
            />
          </Flex>
        </DrawerHeader>

        <DrawerBody p={6}>
          {appMode === 'talent' && isLogin === undefined ? (
            <Flex mt="40px" justifyContent="center" alignItems="center" bg="surface.primary">
              <Spinner />
            </Flex>
          ) : (
            <Flex flexDirection="column" gap={isLogin ? '0px' : '24px'}>
              {!isLogin ? (
                <Flex gap={1} m="0 auto" w="100%">
                  <ChakraLink
                    onClick={(e) => onClickLink(e, hrefTalentRegistration)}
                    href={hrefTalentRegistration}
                    linkTo="talent"
                    w="50%"
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'menu_registration_button' as GTMClickItemName}
                  >
                    <Button
                      size="md"
                      fontWeight="bold"
                      w="100%"
                      h="48px"
                      p="10px 32px"
                      variant="outline"
                      borderRadius="100px"
                      bg="white"
                      _hover={{ bg: 'white' }}
                    >
                      会員登録（無料）
                    </Button>
                  </ChakraLink>
                  <ChakraLink
                    onClick={(e) => onClickLink(e, hrefTalentLogin)}
                    href={hrefTalentLogin}
                    linkTo="talent"
                    w="50%"
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'menu_login_button' as GTMClickItemName}
                  >
                    <Button
                      size="md"
                      fontWeight="bold"
                      w="100%"
                      h="48px"
                      p="10px 32px"
                      borderRadius="100px"
                      bg="text.link"
                      color="white"
                      _hover={{ bg: 'text.link' }}
                    >
                      ログイン
                    </Button>
                  </ChakraLink>
                </Flex>
              ) : (
                <Flex gap={2} w="100%" mb="24px">
                  <ChakraLink
                    onClick={(e) => onClickLink(e, hrefTalentScout)}
                    href={hrefTalentScout}
                    linkTo="talent"
                    w="50%"
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'menu_scout_button' as GTMClickItemName}
                  >
                    <Flex
                      flexDirection="column"
                      gap="8px"
                      p="16px"
                      border="1px solid var(--border-border-primary, #E2E8F0)"
                      borderRadius="8px"
                      position="relative"
                    >
                      <Image src={ThumbUp.src} width={20} height={20} alt="スカウト" />
                      {unreadScoutCount > 0 && (
                        <Box
                          position="absolute"
                          left="36px"
                          width="6px"
                          height="6px"
                          bg="surface.keep"
                          borderRadius="10px"
                        />
                      )}
                      <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                        スカウト
                      </Text>
                      <Text fontWeight={300} fontSize="12px" lineHeight="20px">
                        {unreadScoutCount}件の新規スカウト
                      </Text>
                    </Flex>
                  </ChakraLink>
                  <ChakraLink
                    onClick={(e) => onClickLink(e, hrefTalentJobKeepList)}
                    href={hrefTalentJobKeepList}
                    linkTo="talent"
                    w="50%"
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'menu_keep_button' as GTMClickItemName}
                  >
                    <Flex
                      flexDirection="column"
                      gap="8px"
                      p="16px"
                      border="1px solid var(--border-border-primary, #E2E8F0)"
                      borderRadius="8px"
                    >
                      <Image src={Heart.src} width={20} height={20} alt="キープ" />
                      <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                        キープした求人
                      </Text>
                      <Text fontWeight={300} fontSize="12px" lineHeight="20px">
                        {keptJobsCount}件の求人
                      </Text>
                    </Flex>
                  </ChakraLink>
                </Flex>
              )}

              <Flex flexDirection="column">
                <ChakraLink
                  w="100%"
                  h="100%"
                  onClick={(e) => onClickLink(e, hrefTalentHome)}
                  href={hrefTalentHome}
                  linkTo="talent"
                  _hover={{ textDecoration: 'none' }}
                  data-gtm-click-item={'menu_top_link' as GTMClickItemName}
                >
                  <Box p="16px 8px">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex gap={2}>
                        <HomeIcon category="footer" />
                        <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                          Zキャリア ホーム
                        </Text>
                      </Flex>
                      <ChevronRightIcon color="#A0AEC0" width="16px" height="16px" />
                    </Flex>
                  </Box>
                </ChakraLink>

                {isLogin && (
                  <ChakraLink
                    onClick={(e) => onClickLink(e, hrefTalentScout)}
                    href={hrefTalentScout}
                    linkTo="talent"
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'menu_scout_link' as GTMClickItemName}
                  >
                    <Box p="16px 8px">
                      <Flex justifyContent="space-between" alignItems="center">
                        <Flex gap={2} alignItems="center">
                          <Image src={ThumbUp.src} width={20} height={20} alt="スカウト" />
                          <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                            スカウト
                          </Text>
                          {unreadScoutCount > 0 && (
                            <Box
                              p="3px 4px"
                              bg="surface.keep"
                              borderRadius="10px"
                              fontSize="10px"
                              lineHeight="10px"
                              color="white"
                            >
                              {unreadScoutCount}
                            </Box>
                          )}
                        </Flex>
                        <ChevronRightIcon color="#A0AEC0" width="16px" height="16px" />
                      </Flex>
                    </Box>
                  </ChakraLink>
                )}

                {isLogin && (
                  <ChakraLink
                    onClick={(e) => onClickLink(e, hrefTalentInquiryList)}
                    href={hrefTalentInquiryList}
                    linkTo="talent"
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'menu_inquiry_list_link' as GTMClickItemName}
                  >
                    <Box p="16px 8px">
                      <Flex justifyContent="space-between" alignItems="center">
                        <Flex gap={2}>
                          <Image src={ChatAlt.src} width={20} height={20} alt="問合わせした求人" />
                          <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                            問合わせした求人
                          </Text>
                        </Flex>
                        <ChevronRightIcon color="#A0AEC0" width="16px" height="16px" />
                      </Flex>
                    </Box>
                  </ChakraLink>
                )}

                <ChakraLink
                  onClick={(e) => onClickLink(e, hrefTalentJobKeepList)}
                  href={hrefTalentJobKeepList}
                  linkTo="talent"
                  _hover={{ textDecoration: 'none' }}
                  data-gtm-click-item={'menu_keep_link' as GTMClickItemName}
                >
                  <Box p="16px 8px">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex gap={2}>
                        <Image src={Heart.src} width={20} height={20} alt="キープ" />
                        <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                          キープした求人
                        </Text>
                      </Flex>
                      <ChevronRightIcon color="#A0AEC0" width="16px" height="16px" />
                    </Flex>
                  </Box>
                </ChakraLink>
                <ChakraLink
                  onClick={(e) => onClickLink(e, hrefTalentJob)}
                  href={hrefTalentJob}
                  linkTo="talent"
                  _hover={{ textDecoration: 'none' }}
                  data-gtm-click-item={'menu_job_list_link' as GTMClickItemName}
                >
                  <Box p="16px 8px">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex gap={2}>
                        <Image src={DocumentSearch.src} width={20} height={20} alt="求人を探す" />
                        <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                          求人を探す
                        </Text>
                      </Flex>
                      <ChevronRightIcon color="#A0AEC0" width="16px" height="16px" />
                    </Flex>
                  </Box>
                </ChakraLink>
                {/*
                <ChakraLink
                  onClick={onClickLink}
                  href=""
                  _hover={{ textDecoration: 'none' }}
                  data-gtm-click-item={'menu_agent_consultation_link' as GTMClickItemName}
                >
                  <Box p="16px 8px">
                    <Flex alignItems="center" justifyContent="space-between">
                      <Flex gap={2.5} alignItems="center" textAlign="center">
                        <Users color="#1A202C" width="20" height="20" />
                        <Text fontSize="14px" fontWeight="bold" lineHeight="20px">
                          求人を紹介してもらう
                        </Text>
                      </Flex>
                      <ChevronRightIcon color="#A0AEC0" width="16px" height="16px" />
                    </Flex>
                  </Box>
                </ChakraLink>
                */}

                {/* TODO: 導線開放時に判定を!guideAppUrlにかえる */}
                <ChakraLink
                  onClick={(e) => onClickLink(e, hrefGuideHome)}
                  href={hrefGuideHome}
                  linkTo="guide"
                  _hover={{ textDecoration: 'none' }}
                  data-gtm-click-item={'menu_scout_guide' as GTMClickItemName}
                >
                  <Box p="16px 8px">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex gap={2}>
                        <Image
                          src={AcademicCap.src}
                          width={20}
                          height={20}
                          alt="就職・転職ガイド"
                        />
                        <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                          就職・転職ガイド
                        </Text>
                      </Flex>
                      <ChevronRightIcon color="#A0AEC0" width="16px" height="16px" />
                    </Flex>
                  </Box>
                </ChakraLink>
              </Flex>
              {/* TODO: 決まり次第差し替え */}
              {!isLogin && (
                <ChakraLink
                  p="8px"
                  onClick={(e) => onClickLink(e, hrefTalentRegistration)}
                  href={hrefTalentRegistration}
                  linkTo="talent"
                  _hover={{ textDecoration: 'none' }}
                  data-gtm-click-item={'menu_scout_banner' as GTMClickItemName}
                >
                  <ChakraImage src={Banner.src} alt="バナー" width="100%" borderRadius="8px" />
                </ChakraLink>
              )}

              {isLogin && (
                <>
                  <Divider my="8px" />
                  <ChakraLink
                    onClick={(e) => onClickLink(e, hrefTalentAccount)}
                    href={hrefTalentAccount}
                    linkTo="talent"
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'menu_account_info_link' as GTMClickItemName}
                  >
                    <Box p="16px 8px">
                      <Flex justifyContent="space-between" alignItems="center">
                        <Flex gap={2}>
                          <Image src={UserIcon.src} width={20} height={20} alt="キープ" />
                          <Text fontWeight="bold" fontSize="14px" lineHeight="20px">
                            アカウント情報
                          </Text>
                        </Flex>
                        <ChevronRightIcon color="#A0AEC0" width="16px" height="16px" />
                      </Flex>
                    </Box>
                  </ChakraLink>
                </>
              )}
            </Flex>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
})

export default Menu
