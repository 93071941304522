import { Flex, Spinner } from '@chakra-ui/react'

type SpinnerProps = {
  /**
   * loading: 画面遷移時のローディング画面（指定がなければloadingを採用）
   * normal: デフォルトのChakraUIのSpinner
   */
  spinnerType?: 'loading' | 'normal'
}

export const LoadingPageElm: React.FC<SpinnerProps> = (props: SpinnerProps) => {
  const { spinnerType = 'loading' } = props

  return (
    <>
      {spinnerType === 'loading' && (
        <Flex
          maxWidth="640px"
          mt="1px"
          h="100svh"
          justifyContent="center"
          alignItems="center"
          bg="surface.primary"
          margin="1px auto 0"
        >
          <Spinner emptyColor="brand.green-right" color="brand.green" size="xl" />
        </Flex>
      )}

      {spinnerType === 'normal' && <Spinner />}
    </>
  )
}
