import { useEffect } from 'react'
import { useAtom } from 'jotai'

import { getPublicApiUrl } from '@/api/apiHostNameSwitcher'
import { masterAtom } from '@/globalState/master'
import { LargeMasterListItem } from '@/oas/public/api'

export const useMaster = (category: string[]) => {
  // masterの値をglobalStateに保存する
  const [master, setMaster] = useAtom(masterAtom)

  const fetchMaster = async (categoryQuery: string) => {
    const response = await fetch(`${getPublicApiUrl()}/master?${categoryQuery}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    const data: LargeMasterListItem[] = await response.json()
    setMaster((prev) => [...prev, ...data])
  }

  // 仕様として、既にmasterの中に取得しているものを再度取得することはないようにする
  // localではstrict modeのため値が重複して入ってきているが、productionでは問題ない（はず）
  useEffect(() => {
    if (category.length === 0) return
    const masterCategories = master.map((v) => v.category)
    const newCategory = category.filter((v) => !masterCategories.includes(v))
    if (newCategory.length === 0) return
    const categoryQuery = newCategory.map((v) => `category%5B%5D=${v}`).join('&')
    fetchMaster(categoryQuery)
  }, [category])
}
